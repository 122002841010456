import { useContext, useState } from "react"
import {
  ActualitysData,
  ActualitysTableProps,
} from "../../../types/actualityTypes"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { deleteActuality } from "../../../services/actuality"
import { queryClient } from "../../../utils/reactQueryClient"
import { isValidUrl, limitContent } from "../../../utils/functions"
import { MdEdit } from "react-icons/md"
import DeleteButton from "../../shared/DeleteButton"
import ActualityModal from "../modal/ActualityModal"
import { UserContext } from "../../../context/AuthContext"

const ActualityTable: React.FC<ActualitysTableProps> = ({ actualitys }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingActuality, setEditingActuality] = useState<
    ActualitysData | undefined
  >(undefined)

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeActuality } = useCustomMutation({
    mutationFn: deleteActuality,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["actuality"] })
    },
  })

  const handleEdit = (actuality: ActualitysData) => {
    setEditingActuality(actuality)
    setIsModalOpen(true)
  }

  const handleDelete = (actualityId: string) => {
    removeActuality(actualityId)
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text">
              الواجهة
            </th>
            <th scope="col" className="th-text">
              العنوان
            </th>
            <th scope="col" className="th-text">
              تاريخ الإنشاء
            </th>
            <th scope="col" className="th-text w-[50%]">
              المحتوى
            </th>
            <th scope="col" className="th-text text-center">
              تعديل
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {actualitys.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={5}>
                لا توجد بيانات{""}
              </td>
            </tr>
          ) : (
            actualitys.map((actuality) => (
              <tr key={actuality.id} className="hover:bg-gray-50">
                <td className="td-text">
                  <img
                    src={
                      isValidUrl(actuality.imgUrl)
                        ? actuality.imgUrl
                        : `${process.env.REACT_APP_API_URL}/storage/downloadFile/${actuality?.imgUrl}`
                    }
                    className="w-12 h-12 text-green-500 ml-4"
                    alt="CoverImage"
                  />
                </td>
                <td className="td-text">{actuality.title}</td>
                <td className="td-text">
                  {new Date(actuality.creationDate).toLocaleDateString("fr-EG")}
                </td>
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[50%]">
                  {limitContent(actuality.description)}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    onClick={() => handleEdit(actuality)}
                    className="btn-edit"
                  >
                    <MdEdit className="w-5 h-5" />
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(actuality.id)}
                      itemId={actuality.id}
                      itemName={actuality.title}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <ActualityModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingActuality(undefined)
          }}
          actuality={editingActuality}
        />
      )}
    </div>
  )
}

export default ActualityTable
