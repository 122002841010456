import { MdEdit } from "react-icons/md"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import DeleteButton from "../../shared/DeleteButton"
import { useContext, useState } from "react"
import { LessonsData } from "../../../types/lessonsTypes"
import { deleteLesson } from "../../../services/lessons"
import LessonModal from "../modal/LessonModal"
import { isValidUrl } from "../../../utils/functions"
import { UserContext } from "../../../context/AuthContext"
interface LessonsTableProps {
  lessons: LessonsData[]
}

const LessonsTable: React.FC<LessonsTableProps> = ({ lessons }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingLesson, setEditingLesson] = useState<LessonsData | undefined>(
    undefined,
  )

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeLesson } = useCustomMutation({
    mutationFn: deleteLesson,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["lessons"] })
    },
  })

  const handleEdit = (lesson: LessonsData) => {
    setEditingLesson(lesson)
    setIsModalOpen(true)
  }

  const handleDelete = (lessonId: string) => {
    removeLesson(lessonId)
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text">
              الواجهة
            </th>
            <th scope="col" className="th-text">
              العنوان
            </th>
            <th scope="col" className="th-text">
              تاريخ الإنشاء
            </th>
            <th scope="col" className="th-text text-center ">
              تعديل
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {lessons?.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={4}>
                لا توجد بيانات{" "}
              </td>
            </tr>
          ) : (
            lessons.map((lesson) => (
              <tr key={lesson.id} className="hover:bg-gray-50">
                <td className="td-text">
                  <img
                    src={
                      isValidUrl(lesson.imgCover)
                        ? lesson.imgCover
                        : `${process.env.REACT_APP_API_URL}/storage/downloadFile/${lesson?.imgCover}`
                    }
                    className="w-12 h-12 text-green-500 ml-4"
                    alt="Your Logo"
                  />
                </td>
                <td className="td-text">{lesson.title}</td>
                <td className="td-text">
                  {lesson?.dateLesson
                    ? new Date(lesson?.dateLesson).toLocaleDateString("fr-EG")
                    : "-"}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    onClick={() => handleEdit(lesson)}
                    className="btn-edit"
                  >
                    <MdEdit className="w-5 h-5" />
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(lesson.id)}
                      itemId={lesson.id}
                      itemName={lesson.title}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <LessonModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingLesson(undefined)
          }}
          lesson={editingLesson}
        />
      )}
    </div>
  )
}

export default LessonsTable
