import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { MdEdit } from "react-icons/md"
import { queryClient } from "../../../utils/reactQueryClient"
import useCustomMutation from "../../../hooks/useCustomMutation"
import DeleteButton from "../../shared/DeleteButton"
import { isValidUrl } from "../../../utils/functions"
import { UserContext } from "../../../context/AuthContext"
import { LessonsCategory } from "../../../types/lessonsTypes"
import { deleteLessonCategory } from "../../../services/lessons"
import LessonCategoryModal from "../modal/LessonCategory"

const LessonCategorysList: React.FC<any> = ({ lessonsCategorys }) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingLessonCategory, setEditingLessonCategory] = useState<
    LessonsCategory | undefined
  >(undefined)

  const { user } = useContext(UserContext)
  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    lessonCategory: LessonsCategory,
  ) => {
    e.stopPropagation()
    setEditingLessonCategory(lessonCategory)
    setIsModalOpen(true)
  }

  const { mutate: removeLessonCategory } = useCustomMutation({
    mutationFn: deleteLessonCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["lessonCategories"] })
    },
  })

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    lessonCategoryId: string,
  ) => {
    e.stopPropagation()
    removeLessonCategory(lessonCategoryId)
  }

  const handleNavigate = (lessonCategoryId: string) => {
    navigate(`/educational-lessons/${lessonCategoryId}`)
  }

  return (
    <div className="space-y-4">
      {lessonsCategorys?.map((lessonsCategory: LessonsCategory) => (
        <div
          key={lessonsCategory.id}
          onClick={() => handleNavigate(lessonsCategory.id)}
          className="cursor-pointer mb-6 rounded-lg bg-white overflow-hidden transition-all duration-300 hover:shadow-md flex justify-between items-center p-4"
          style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
        >
          <div className="flex items-center">
            <img
              src={
                isValidUrl(lessonsCategory?.coverImgUrl)
                  ? lessonsCategory?.coverImgUrl
                  : `${process.env.REACT_APP_API_URL}/storage/downloadFile/${lessonsCategory?.coverImgUrl}`
              }
              className="w-12 h-12 text-green-500 ml-4"
              alt="Your Logo"
            />
            <h3 className="text-lg font-medium text-gray-800">
              {lessonsCategory.title}
            </h3>
          </div>
          <div className="flex items-center">
            <button
              onClick={(e) => handleEdit(e, lessonsCategory)}
              className="btn-edit"
            >
              <MdEdit className="w-5 h-5" />
            </button>
            {isSuperAdmin && (
              <DeleteButton
                onDelete={handleDelete}
                itemId={lessonsCategory.id}
                itemName={lessonsCategory.title}
              />
            )}
          </div>
        </div>
      ))}
      {isModalOpen && (
        <LessonCategoryModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingLessonCategory(undefined)
          }}
          lessonCategory={editingLessonCategory}
        />
      )}
    </div>
  )
}

export default LessonCategorysList
