import { MdEdit } from "react-icons/md"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { deleteDefinition } from "../../../services/definitions"
import { DefinitionData } from "../../../types/definitionsTypes"
import { queryClient } from "../../../utils/reactQueryClient"
import DeleteButton from "../../shared/DeleteButton"
import { useContext, useState } from "react"
import DefinitionModal from "../modal/DefinitionModal"
import { isValidUrl, limitContent } from "../../../utils/functions"
import { UserContext } from "../../../context/AuthContext"

interface DefinitionsTableProps {
  definitions: DefinitionData[]
}

const DefinitionsTable: React.FC<DefinitionsTableProps> = ({ definitions }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingDefinition, setEditingDefinition] = useState<
    DefinitionData | undefined
  >(undefined)

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeDefinition } = useCustomMutation({
    mutationFn: deleteDefinition,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["definitionBycategories"] })
    },
  })

  const handleEdit = (definition: DefinitionData) => {
    setEditingDefinition(definition)
    setIsModalOpen(true)
  }

  const handleDelete = (definitionId: string) => {
    removeDefinition(definitionId)
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text">
              الواجهة
            </th>
            <th className="th-text">العنوان</th>
            <th className="th-text w-[50%]">المحتوى</th>
            <th className="th-text text-center">تعديل</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {definitions.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={4}>
                لا توجد بيانات{" "}
              </td>
            </tr>
          ) : (
            definitions.map((definition) => (
              <tr key={definition.id} className="hover:bg-gray-50">
                <td className="td-text">
                  <img
                    src={
                      isValidUrl(definition.coverImgUrl)
                        ? definition.coverImgUrl
                        : `${process.env.REACT_APP_API_URL}/storage/downloadFile/${definition?.coverImgUrl}`
                    }
                    className="w-12 h-12 text-green-500 ml-4"
                    alt="CoverImage"
                  />
                </td>
                <td className="td-text">{definition.title}</td>
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[50%]">
                  {limitContent(definition.content)}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    onClick={() => handleEdit(definition)}
                    className="btn-edit"
                  >
                    <MdEdit className="w-5 h-5 " />
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(definition.id)}
                      itemId={definition.id}
                      itemName={definition.title}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <DefinitionModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingDefinition(undefined)
          }}
          definition={editingDefinition}
        />
      )}
    </div>
  )
}

export default DefinitionsTable
