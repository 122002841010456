import { MdEdit } from "react-icons/md"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import DeleteButton from "../../shared/DeleteButton"
import { useContext, useState } from "react"
import { SermonsData } from "../../../types/sermonsTypes"
import { deleteSermon } from "../../../services/sermons"
import SermonModal from "../modal/SermonModal"
import { isValidUrl, limitContent } from "../../../utils/functions"
import { UserContext } from "../../../context/AuthContext"
interface SermonsTableProps {
  sermons: SermonsData[]
}

const SermonsTable: React.FC<SermonsTableProps> = ({ sermons }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingSermon, setEditingSermon] = useState<SermonsData | undefined>(
    undefined,
  )

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeSermon } = useCustomMutation({
    mutationFn: deleteSermon,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["sermons"] })
    },
  })

  const handleEdit = (sermon: SermonsData) => {
    setEditingSermon(sermon)
    setIsModalOpen(true)
  }

  const handleDelete = (sermonId: string) => {
    removeSermon(sermonId)
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text">
              الواجهة
            </th>
            <th scope="col" className="th-text max-w-[10rem] text-wrap">
              العنوان
            </th>
            <th scope="col" className="th-text">
              تاريخ الخطبة
            </th>
            <th scope="col" className="th-text w-[40%]">
              المحتوى
            </th>
            <th scope="col" className="th-text text-center">
              تعديل
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sermons?.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={5}>
                لا توجد بيانات{" "}
              </td>
            </tr>
          ) : (
            sermons.map((sermon) => (
              <tr key={sermon.id} className="hover:bg-gray-50">
                <td className="td-text">
                  <img
                    src={
                      isValidUrl(sermon.coverImgUrl)
                        ? sermon.coverImgUrl
                        : `${process.env.REACT_APP_API_URL}/storage/downloadFile/${sermon?.coverImgUrl}`
                    }
                    className="w-12 h-12 text-green-500 ml-4"
                    alt="Your Logo"
                  />
                </td>
                <td className="td-text text-wrap text-xs">{sermon.title}</td>
                <td className="td-text">
                  {sermon.dateFriday
                    ? new Date(sermon.dateFriday).toLocaleDateString("fr-EG")
                    : "-"}
                </td>
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[40%]">
                  {limitContent(sermon.content)}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    onClick={() => handleEdit(sermon)}
                    className="btn-edit"
                  >
                    <MdEdit className="w-5 h-5" />
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(sermon.id)}
                      itemId={sermon.id}
                      itemName={sermon.title}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <SermonModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingSermon(undefined)
          }}
          sermon={editingSermon}
        />
      )}
    </div>
  )
}

export default SermonsTable
