import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { MdEdit } from "react-icons/md"
import { queryClient } from "../../../utils/reactQueryClient"
import useCustomMutation from "../../../hooks/useCustomMutation"
import DeleteButton from "../../shared/DeleteButton"
import { ProgramsCategory } from "../../../types/programsTypes"
import { deleteProgramCategory } from "../../../services/programs"
import ProgramCategoryModal from "../modal/ProgramCategoryModal"
import { isValidUrl } from "../../../utils/functions"
import { UserContext } from "../../../context/AuthContext"

const ProgramCategoriesList: React.FC<any> = ({ programCategories }) => {
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingProgramCategory, setEditingProgramCategory] = useState<
    ProgramsCategory | undefined
  >(undefined)

  const { user } = useContext(UserContext)
  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const handleEdit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    programCategory: ProgramsCategory,
  ) => {
    e.stopPropagation()
    setEditingProgramCategory(programCategory)
    setIsModalOpen(true)
  }

  const { mutate: removeProgramCategory } = useCustomMutation({
    mutationFn: deleteProgramCategory,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["programCategories"] })
    },
  })

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    programCategoryId: string,
  ) => {
    e.stopPropagation()
    removeProgramCategory(programCategoryId)
  }

  const handleNavigate = (programCategoryId: string) => {
    navigate(`/scientific-programs/${programCategoryId}`)
  }

  return (
    <div className="space-y-4">
      {programCategories?.content?.map((programsCategory: ProgramsCategory) => (
        <div
          key={programsCategory.id}
          onClick={() => handleNavigate(programsCategory.id)}
          className="cursor-pointer mb-6 rounded-lg bg-white overflow-hidden transition-all duration-300 hover:shadow-md flex justify-between items-center p-4"
          style={{ boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
        >
          <div className="flex items-center">
            <img
              src={
                isValidUrl(programsCategory?.coverImgUrl)
                  ? programsCategory?.coverImgUrl
                  : `${process.env.REACT_APP_API_URL}/storage/downloadFile/${programsCategory?.coverImgUrl}`
              }
              className="w-12 h-12 text-green-500 ml-4"
              alt="Your Logo"
            />
            <h3 className="text-lg font-medium text-gray-800">
              {programsCategory.title}
            </h3>
          </div>
          <div className="flex items-center">
            <button
              onClick={(e) => handleEdit(e, programsCategory)}
              className="btn-edit"
            >
              <MdEdit className="w-5 h-5" />
            </button>
            {isSuperAdmin && (
              <DeleteButton
                onDelete={handleDelete}
                itemId={programsCategory.id}
                itemName={programsCategory.title}
              />
            )}
          </div>
        </div>
      ))}

      {isModalOpen && (
        <ProgramCategoryModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingProgramCategory(undefined)
          }}
          programCategory={editingProgramCategory}
        />
      )}
    </div>
  )
}

export default ProgramCategoriesList
