import { MdEdit } from "react-icons/md"
import useCustomMutation from "../../../hooks/useCustomMutation"
import { queryClient } from "../../../utils/reactQueryClient"
import DeleteButton from "../../shared/DeleteButton"
import { useContext, useState } from "react"
import { MethodsData, MethodsTableProps } from "../../../types/methodsTypes"
import { deleteMethod } from "../../../services/methods"
import MethodModal from "../modal/MethodModal"
import { isValidUrl, limitContent } from "../../../utils/functions"
import { UserContext } from "../../../context/AuthContext"

const MethodsTable: React.FC<MethodsTableProps> = ({ methods }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingMethod, setEditingMethod] = useState<MethodsData | undefined>(
    undefined,
  )

  const { user } = useContext(UserContext)

  const isSuperAdmin = user?.role?.role === "SUPER_ADMIN"

  const { mutate: removeMethod } = useCustomMutation({
    mutationFn: deleteMethod,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["methods"] })
    },
  })

  const handleEdit = (method: MethodsData) => {
    setEditingMethod(method)
    setIsModalOpen(true)
  }

  const handleDelete = (methodId: string) => {
    removeMethod(methodId)
  }

  return (
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-200 shadow-sm">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="th-text">
              الواجهة
            </th>
            <th scope="col" className="th-text">
              صورة المحتوى
            </th>
            <th scope="col" className="th-text">
              العنوان
            </th>
            <th scope="col" className="th-text w-[50%]">
              المحتوى
            </th>
            <th scope="col" className="th-text text-center">
              تعديل
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {methods?.length === 0 ? (
            <tr>
              <td className="td-text text-center" colSpan={5}>
                لا توجد بيانات{" "}
              </td>
            </tr>
          ) : (
            methods.map((method) => (
              <tr key={method.id} className="hover:bg-gray-50">
                <td className="td-text">
                  <img
                    src={
                      isValidUrl(method.coverImgUrl)
                        ? method.coverImgUrl
                        : `${process.env.REACT_APP_API_URL}/storage/downloadFile/${method?.coverImgUrl}`
                    }
                    className="w-12 h-12 text-green-500 ml-4"
                    alt="CoverImage"
                  />
                </td>
                <td className="td-text">
                  <img
                    src={
                      isValidUrl(method.contentImgUrl)
                        ? method.contentImgUrl
                        : `${process.env.REACT_APP_API_URL}/storage/downloadFile/${method?.contentImgUrl}`
                    }
                    className="w-12 h-12 text-green-500 ml-4"
                    alt="CoverImage"
                  />
                </td>
                <td className="td-text">{method.title}</td>
                <td className="px-6 py-4 text-right text-xs font-medium text-gray-900 tracking-wider w-[50%]">
                  {limitContent(method.content)}
                </td>
                <td className="whitespace-nowrap text-left px-6 py-3">
                  <button
                    onClick={() => handleEdit(method)}
                    className="btn-edit"
                  >
                    <MdEdit className="w-5 h-5" />
                  </button>
                  {isSuperAdmin && (
                    <DeleteButton
                      onDelete={() => handleDelete(method.id)}
                      itemId={method.id}
                      itemName={method.title}
                    />
                  )}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <MethodModal
          onClose={() => {
            setIsModalOpen(false)
            setEditingMethod(undefined)
          }}
          method={editingMethod}
        />
      )}
    </div>
  )
}

export default MethodsTable
