import { MdOutlineCancel } from "react-icons/md"
import { checkExtension, isValidUrl } from "../../utils/functions"

interface PlayerProps {
  onClose: () => void
  url: string
}

const Player: React.FC<PlayerProps> = ({ url, onClose }) => {
  const extension = checkExtension(url)

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-7 rounded-md w-full max-w-md">
        <div className="flex justify-between items-center">
          <h1 className="text-2xl text-nowrap font-bold text-[#8C6633]">
            {" "}
            محتوى الملف
          </h1>
          <button
            className="hover:text-gray-500 duration-700"
            onClick={onClose}
          >
            <MdOutlineCancel className="h-6 w-auto" />
          </button>
        </div>
        <hr className="my-4" />
        <div className="flex items-center justify-center">
          {extension === "mp4" ? (
            <video
              className="w-full"
              src={
                isValidUrl(url)
                  ? url
                  : `${process.env.REACT_APP_API_URL}/storage/downloadImage/${url}`
              }
              controls
            />
          ) : (
            <audio
              className="w-full"
              src={
                isValidUrl(url)
                  ? url
                  : `${process.env.REACT_APP_API_URL}/storage/downloadImage/${url}`
              }
              controls
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Player
