import { useState, useEffect } from "react"
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md"
import { filterByExtensions, isValidUrl } from "../../../utils/functions"

interface CarouselModalProps {
  tour?: string[]
}

const Carousel: React.FC<CarouselModalProps> = ({ tour }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const totalSlides = tour?.length || 0

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === totalSlides - 1 ? 0 : prevSlide + 1,
    )
  }

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? totalSlides - 1 : prevSlide - 1,
    )
  }

  return (
    <div className="carousel-container flex items-center">
      <button
        className="carousel-button carousel-button-next"
        onClick={handleNextSlide}
      >
        <MdNavigateNext className="h-5 w-5" />
      </button>
      <div className="carousel-track duration-700">
        {tour?.[currentSlide] && (
          <img
            src={
              isValidUrl(tour?.[currentSlide])
                ? tour?.[currentSlide]
                : `${process.env.REACT_APP_API_URL}/storage/downloadFile/${tour?.[currentSlide]}`
            }
            alt="Trip Picture"
            className="carousel-slide w-12 h-12"
          />
        )}
      </div>
      <button
        className="carousel-button carousel-button-prev "
        onClick={handlePrevSlide}
      >
        <MdNavigateBefore className="h-5 w-5" />
      </button>
    </div>
  )
}

export default Carousel
